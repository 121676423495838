// ? 封装localStorage的方法
// ? exp:localStorageData('get',userInfo,data)
export function localStorageData(method, name, obj) {
  /*
   * 参数说明：
   * method：get获取，set存入或覆盖，clean清除
   * name:localStorage的名称
   * obj:存入的内容，可以是任意类型
   * localStorage.getItem(key):获取指定key本地存储的值
   * localStorage.setItem(key,value)：将value存储到key字段
   * localStorage.removeItem(key):删除指定key本地存储的值
   * */

  switch(method) {
    case 'get':
      if(localStorage.getItem(name + '_obj')) {
        return JSON.parse(localStorage.getItem(name + '_obj'))
      } else if(localStorage.getItem(name + '_str')) {
        return localStorage.getItem(name + '_str')
      } else {
        return null
      }
    case 'set':
      localStorageData('clean', name)
      if(typeof obj == 'object') {
        localStorage.setItem(name + '_obj', JSON.stringify(obj))
      } else {
        localStorage.setItem(name + '_str', obj)
      }
      return true
    case 'clean':
      localStorage.removeItem(name + '_obj')
      localStorage.removeItem(name + '_str')
      return true
  }
}

// ? 封装sessionStorage的方法
// ? exp:sessionStorageData('get',userInfo,data)
export function sessionStorageData(method, name, obj) {
  /*
   * 参数说明：
   * method：get获取，set存入或覆盖，clean清除
   * name:session的名称
   * obj:存入的内容，可以是任意类型
   * */
  switch(method) {
    case 'get':
      if(sessionStorage.getItem(name + '_obj')) {
        return JSON.parse(sessionStorage.getItem(name + '_obj'))
      } else if(sessionStorage.getItem(name + '_str')) {
        return sessionStorage.getItem(name + '_str')
      } else {
        return null
      }
    case 'set':
      sessionStorageData('clean', name)
      if(typeof obj == 'object') {
        sessionStorage.setItem(name + '_obj', JSON.stringify(obj))
      } else {
        sessionStorage.setItem(name + '_str', obj)
      }
      return true
    case 'clean':
      sessionStorage.removeItem(name + '_obj')
      sessionStorage.removeItem(name + '_str')
      return true
  }
}

// 获取页面参数
export function getUrlKey(name) {
  let data =
    decodeURIComponent(
      (new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
        location.href
      ) || [''])[1].replace(/\+/g, '%20')
    ) || null
  return data
}

export function convertingNumbers(num) {
  const list = [
    '零',
    '一',
    '二',
    '三',
    '四',
    '五',
    '六',
    '日',
    '八',
    '九',
    '十',
  ]
  return list[num]
}

// 节流
export const throttle = (fn, delay) => {
  // 定义上次触发时间
  let last = 0
  return (...args) => {
    const now = +Date.now()
    if(now > last + delay) {
      last = now
      fn.apply(this, args)
    }
  }
}

// 防抖
export const Debounce = (fn, delay, thDelay) => {
  let last = 0
  let timer
  return (...args) => {
    // 判断定时器是否存在，清除定时器
    if(timer) {
      clearTimeout(timer)
    }

    const now = +Date.now()

    if(now > last + thDelay) {
      last = now
      // 重新调用setTimeout
      timer = setTimeout(() => {
        fn.apply(this, args)
      }, delay)
    }
  }
}

export const getQueryVariable = (variable) => {
  let query = window.location.search.substring(1)
  let vars = query.split('&')
  for(let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=')
    if(pair[0] == variable) {
      return pair[1]
    }
  }
  return false
}
//格式化日期
Date.prototype.format = function(fmt) {
  let o = {
    'M+': this.getMonth() + 1, //月份
    'd+': this.getDate(), //日
    'h+': this.getHours(), //小时
    'm+': this.getMinutes(), //分
    's+': this.getSeconds(), //秒
    'q+': Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds() //毫秒
  };

  if(/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, String(this.getFullYear()).substr(4 - RegExp.$1.length));
  }
  for(let k in o) {
    if(new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(String(o[k]).length));
    }
  }
  return fmt;
};

//格式化日期
export function formatDate(nS, format) {
  //日期格式化
  if(!nS) {
    return '';
  }
  format = format || 'yyyy-MM-dd hh:mm:ss';
  return new Date(nS).format(format);
}