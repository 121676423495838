/**
 * 创建人：逍遥
 * 创建页面：vue-router
 */

import Vue from 'vue'
import VueRouter from 'vue-router'
// import { MessageBox } from 'element-ui'

import Layout from '@/layout'
import AwardCard from '@/layout/awardCard'
import store from '@/store/'

Vue.use(VueRouter)

export const constantRoutes = [
  {
    path: '/attendance',
    component: Layout,
    children: [
      {
        path: 'allStatistic',
        name: '全校考勤统计',
        component: () => import('@/views/attendance/analysis/allStatistic'),
      },
      {
        path: 'gradeStatistic',
        name: '年级考勤统计',
        component: () => import('@/views/attendance/analysis/gradeStatistic'),
      },
      {
        path: 'classStatistic',
        name: '班级考勤统计',
        component: () => import('@/views/attendance/analysis/classStatistic'),
      },
      {
        path: 'studentStatistic',
        name: '学生考勤统计',
        component: () => import('@/views/attendance/analysis/studentStatistic'),
      },
      {
        path: 'setting',
        name: '考勤设置',
        component: () => import('@/views/attendance/setting/index'),
      },
      {
        path: 'teacherSetting',
        name: '考勤设置',
        component: () => import('@/views/attendance/teacherSetting/index'),
      },
      {
        path: 'teacherStatistic',
        name: '考勤明细',
        component: () => import('@/views/attendance/teacherStatistic/index'),
      },
      {
        path: 'teacherDaping',
        name: '考勤统计',
        component: () => import('@/views/attendance/teacherStatistic/daping'),
      },
      {
        path: 'teacherStatisticDetail', 
        name: '考勤明细',
        component: () => import('@/views/attendance/teacherStatistic/teacherDetail'),
      },
      {
        path: 'leaveSchool',
        name: '放学管理',
        component: () => import('@/views/attendance/leaveSchool/index'),
      },
      {
        path: 'leaveSchoolDetail',
        name: '放学管理',
        component: () => import('@/views/attendance/leaveSchool/detail'),
      }
    ]
  },
  {
    path: '/awardCardHeader',
    component: AwardCard,
    children: [
      {
        path: 'teacherStatistic',
        name: '教师统计',
        component: () => import('@/views/awardCard/teacherStatistic'),
      },
      {
        path: 'studentStatistic',
        name: '学生统计',
        component: () => import('@/views/awardCard/studentStatistic'),
      },
      {
        path: 'cardSetting',
        name: '卡片设置',
        component: () => import('@/views/awardCard/cardSetting'),
      }
    ]
  },
  {
    path: '/chart/:schoolId/:studentCode/:sn',
    name: '图表',
    component: () => import('@/views/chart/index'),
  },
  {
    path: '/daping',
    name: '大屏展示',
    component: () => import('@/views/daping/index'),
    meta: {
      isFullScren: true
    }
  }
]

const createRouter = () =>
  new VueRouter({
    scrollBehavior: () => ({
      y: 0,
    }),
    mode: 'history',
    base: 'aaw',//奖卡出入校
    routes: constantRoutes,
  })

const router = createRouter()



export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher
}

function requestFullScreen() {
  let element = document.documentElement;
  // 判断浏览器设备类型
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {   // 兼容火狐
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {    // 兼容谷歌
    element.webkitRequestFullscreen();
  } else if (element.msRequestFullscreen) {   // 兼容IE
    element.msRequestFullscreen();
  }
}

router.beforeEach(async (to, form, next) => {
  const meta = to.meta || {};
  if (meta.isFullScren) {
    requestFullScreen()
  }
  if (store.getters.token) {
    if (store.getters.isLoad == false) {
      await store.dispatch("getUserInfo", store.getters.token)
    }
  }

  document.title = to.name;
  next()
})

export default router
