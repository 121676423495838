import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/styles/index.scss'
import '@/styles/theme/index.css'
import VueDraggableResizable from 'vue-draggable-resizable'
import Print from 'vue-print-nb'
import { message } from '@/utils/message'
import BasicBlock from '@/components/basicBlock/main'
import AwardCardBasicBlock from '@/components/basicBlock/awardCard'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import publicConfig from '@/assets/js/publicConfig'
Vue.config.productionTip = false
Vue.use(Antd)
Vue.use(Print)
// 可选择导入默认样式
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'

Vue.use(dataV)

Vue.component('vue-draggable-resizable', VueDraggableResizable)
Vue.component('basic-block', BasicBlock);
Vue.component('award-card', AwardCardBasicBlock);
import { UTable, UTableColumn } from 'umy-ui'
Vue.component(UTable.name, UTable)
Vue.component(UTableColumn.name, UTableColumn)
Vue.prototype.$publicConfig = publicConfig
// 集成文前端
import { registerMicroApps, start } from 'qiankun'

Vue.config.productionTip = false

//获取当前格式化日期年月日
Vue.prototype.$cftY = (date) => {
  let dtCur
  if (date) {
    dtCur = new Date(date)
  } else {
    dtCur = new Date()
  }
  var yearCur = dtCur.getFullYear()
  var monCur = dtCur.getMonth() + 1
  var dayCur = dtCur.getDate()
  var hCur = dtCur.getHours()
  var mCur = dtCur.getMinutes()
  // var sCur = dtCur.getSeconds()
  let timeCur =
    yearCur +
    '/' +
    (monCur < 10 ? '0' + monCur : monCur) +
    '/' +
    (dayCur < 10 ? '0' + dayCur : dayCur) + ' ' 
    + (hCur < 10 ? '0' + hCur : hCur) + ':' 
    + (mCur < 10 ? '0' + mCur : mCur)
  //alert(timeCur);// 输出时间
  return timeCur
}

let apps = [
  {
    name: 'tc-performance-web',
    entry: 'http://localhost:9002/cas/',
    //fetch,
    container: '#vue',
    activeRule: '/taskList',
    props: { params: 'hahaha' },
  },
]

registerMicroApps(apps)
// let option = { prefetch: false }
start()

Vue.config.productionTip = false
Vue.use(ElementUI)
// 监听点击事件
// 重写一下element的message
Vue.prototype.$message = message
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
