<template>
  <div class="headers">
    <!-- <h3 class="scourse-title">学生考勤</h3> -->
    <el-tabs v-model="activeName" @tab-click="tabChange">
        <el-tab-pane label="学生统计" name="awardCardHeader/studentStatistic" ></el-tab-pane>
        <el-tab-pane label="教师统计" name="awardCardHeader/teacherStatistic" ></el-tab-pane>
        <el-tab-pane label="卡片设置" name="awardCardHeader/cardSetting" ></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      activeName: "awardCardHeader/studentStatistic",
    };
  },
  created() {
    this.init()
  },
  computed: {
    ...mapGetters(["taskInfo"]),
  },
  methods: {
    init() {
      this.isTeacher = true;
      this.activeName = "awardCardHeader/teacherStatistic";
      if (this.$route.fullPath.indexOf("awardCardHeader/teacherStatistic") > -1) {
        this.activeName = "awardCardHeader/teacherStatistic";
      }else if (this.$route.fullPath.indexOf("awardCardHeader/cardSetting") > -1) {
        this.activeName = "awardCardHeader/cardSetting";
      }else {
        this.activeName = "awardCardHeader/studentStatistic";
      }
      console.log('333', this.activeName)
    },
    tabChange(e) {
      this.$router.push({
        path: "/" + e.name,
      });
    },
  },
  watch: {
    '$route'() {
      this.init()
    }
  }
};
</script>

<style lang="scss">
.headers {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  @include mediaChangeHeightSize(46px);
  @include mediaChangeLineHeightSize(46px);
  .el-tabs {
    .el-tabs__item {
      color: #999999;
      height: 42px;
    }
    .el-tabs__header {
      margin: 0;
    }
    .el-tabs__item.is-active {
      color: $main-color;
    }
    .el-tabs__active-bar {
      height: 2px;
    }
  }
}
</style>
