<!--
 * @Description: 
 * @Author: 小亓
 * @Date: 2023-05-18 15:15:34
 * @LastEditTime: 2023-07-28 15:50:33
 * @LastEditors: 
-->
<template>
  <div class="headers">
    <!-- <h3 class="scourse-title">学生考勤</h3> -->
    <el-tabs v-model="activeName" @tab-click="tabChange">
      <template v-if="isTeacher">
        <el-tab-pane label="考勤统计" v-if="userInfo.tnId == '931'" name="attendance/teacherDaping" ></el-tab-pane>
        <el-tab-pane label="考勤明细" name="attendance/teacherStatistic" ></el-tab-pane>
        <el-tab-pane label="考勤设置" name="attendance/teacherSetting" ></el-tab-pane>
      </template>
      <template v-else>
        <el-tab-pane label="考勤分析" name="attendance/allStatistic"></el-tab-pane>
        <!-- <el-tab-pane label="放学管理" name="attendance/leaveSchool"></el-tab-pane> -->
        <el-tab-pane label="考勤设置" name="attendance/setting"></el-tab-pane>
      </template>
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return { 
      activeName: "attendance/allStatistic",
      isTeacher: false,
    };
  },
  created() {
    this.init()
  },
  computed: {
    ...mapGetters(["taskInfo", 'userInfo']),
  },
  methods: {
    init() {
      if (this.$route.fullPath.indexOf("/teacher") > -1) {
        this.isTeacher = true;
        this.activeName = "attendance/teacherDaping";
        if (this.$route.fullPath.indexOf("attendance/teacherSetting") > -1) {
          this.activeName = "attendance/teacherSetting";
        }else if (this.$route.fullPath.indexOf("attendance/teacherStatistic") > -1) {
          this.activeName = "attendance/teacherStatistic";
        }
      }else {
        this.activeName = "attendance/allStatistic";
        if (this.$route.fullPath.indexOf("/attendance/setting") > -1) {
          this.activeName = "attendance/setting";
        }else if (this.$route.fullPath.indexOf("/attendance/leaveSchool") > -1) {
          this.activeName = "attendance/leaveSchool";
        }else {
          this.activeName = "attendance/allStatistic";
        }
      }
    },
    tabChange(e) {
      this.$router.push({
        path: "/" + e.name,
      });
    },
  },
  watch: {
    '$route'() {
      this.init()
    }
  }
};
</script>

<style lang="scss">
.headers {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  @include mediaChangeHeightSize(46px);
  @include mediaChangeLineHeightSize(46px);
  .el-tabs {
    .el-tabs__item {
      color: #999999;
      height: 47px;
    }
    .el-tabs__header {
      margin: 0;
    }
    .el-tabs__item.is-active {
      color: $main-color;
    }
    .el-tabs__active-bar {
      height: 3px;
    }
  }
}
</style>
