/**
 * 封装axios
 */

import axios from 'axios'
import { getQueryVariable } from '@/utils'
// import store from '@/store'
import { MessageBox, Message } from 'element-ui'
// import BASE_URL from './common-var'
// import router from '@/router'
const TIMEOUT = 1000
const service = axios.create({
  baseURL: 'https://banpaiapi.shuxiaoyi.net/banpai/',
  timeout: 50000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    // Authorization:
    //   sessionStorage.getItem('token') ||
    //   getQueryVariable('token') ||
    //   router.currentRoute.query.token,
    // Referer: 'http://test.91chenzhi.cn/'
  },
})
// axios.defaults.headers.common['Authorization'] =
//   router.currentRoute.query.token || sessionStorage.getItem('token')
// console.log('object :>> ', router.currentRoute.query.token)
console.log(
  'token :>> ',
  sessionStorage.getItem('token'),
  getQueryVariable('token')
)

// request interceptor
service.interceptors.request.use(
  (config) => {
    // config.headers.common.Authorization =
    //   router.currentRoute.query.token || sessionStorage.getItem('token')
    // do something before request is sent

    // if (store.getters.token) {
    //   // let each request carry token
    //   // ['X-Token'] is a custom headers key
    //   // please modify it according to the actual situation
    //   config.headers['X-Token'] = 'token';
    // }
    return config
  },
  (error) => {
    // do something with request error
    console.log('error', error) // for debug
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  (response) => {
    if (response.data.code === 0) {
      Message({
        message: response.data.message || '服务器错误，请稍后再试！',
        type: 'error',
        duration: TIMEOUT,
      })
    }
    return response.data
  },
  (error) => {
    const res = error
    const status = res.status || ''
    switch (status) {
      case 404:
        // 找不到请求地址
        Message({
          message: res.message || '请求失败',
          type: 'error',
          duration: TIMEOUT,
        })
        break

      case 500: // 服务器错误
        Message({
          message: res.message || '服务器错误，请稍后再试！',
          type: 'error',
          duration: TIMEOUT,
        })
        break
      case 504: //服务器繁忙
        MessageBox.confirm('服务器繁忙，清刷新页面重试！', '确认 取消', {
          confirmButtonText: 'Re-Login',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }).then(() => {
          location.reload()
        })
        break
    }
  }
)

export default service
