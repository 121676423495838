<template>
  <div class="content">
    <div class="main_title"> 学生奖卡 </div>
    <div class="main">
      <Header class="headers"></Header>
      <AppMain class="appMain"></AppMain>
    </div>
  </div>
</template>

<script>
//奖卡全局路由页面
import AppMain from "./components/AppMain";
import Header from "./components/awardCardHeader";
export default {
  components: {
    AppMain,
    Header,
  },
  data() {
    return {
    }
  },
  created() {
  },
};
</script>

<style lang="scss" scoped>
.content {
  @include mediaChangeWidthSize(1460px);
  margin: 0 auto;
  // min-width: 1400px;
  // overflow-x: auto;
  height: $height-max;
  box-sizing: $border-box;
  background: #f5f5f5;
  padding: $main-padding 0;

  .main_title {
    font-size: 24px;
    font-weight: 500;
    color: #3c4154;
    margin-bottom: $main-padding;
  }
  .silder {
    width: 166px;
    flex-shrink: 0;
    @include mediaChangeMarginRight(24px);
  }

  .main {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;

    .headers {
      height: 58px;
      background: $main-bg;
      border-radius: 2px;
      padding: 20px 34px 4px 34px;
      border-bottom: 2px solid #eaedf1;
    }

    .appMain {
      height: calc(100% - 118px);
      overflow: auto;
    }
  }
}
</style>
