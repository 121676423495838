import request from './request'
import requests from './requests'
/**
 * 获取奖卡列表
 */

export const getCardConfigList = (schoolId) => {
  return request({
    url: `/awardcard/api/card-config/${schoolId}`,
    method: 'get'
  })
}

/**
 * 新增奖卡
 */

export const addCardConfig = (data) => {
  return request({
    url: '/awardcard/api/card-config',
    method: 'post',
    data,
  })
}

/**
* 修改奖卡
*/

export const updateCardConfig = (data) => {
  return request({
    url: '/awardcard/api/card-config/update',
    method: 'put',
    data,
  })
}


/**
* 删除奖卡
*/

export const deleteCardConfig = (schoolId, id) => {
  return request({
    url: `/awardcard/api/card-config/${schoolId}/${id}`,
    method: 'delete'
  })
}

/**
* 获取默认考勤数据
*/

export const getDefaultConfig = (params) => {
  return request({
    url: '/awardcard/api/school-config/default',
    method: 'get',
    params
  })
}

/**
* 开始下载任务
*/

export const generateCardData = (id) => {
  return request({
    url: `/awardcard/api/task/generateCardData/${id}`,
    method: 'post'
  })
}

/**
* 创建下载任务
*/

export const createdTask = (data) => {
  return request({
    url: '/awardcard/api/task',
    method: 'post',
    data
  })
}

/**
* 获取任务详情
*/

export const getTaskDetail = (taskId) => {
  return request({
    url: `/awardcard/api/task/detail/${taskId}`,
    method: 'get'
  })
}

/**
* 获取所有任务
*/

export const getAllTask = (schoolId) => {
  return request({
    url: `/awardcard/api/task/${schoolId}`,
    method: 'get'
  })
}

// /**
// * 下载文件
// */

// export const downloadFile = (taskId) => {
//   return request({
//     url: `/awardcard/api/task/${taskId}`,
//     method: 'get',
//     responseType: 'blob'
//   })
// }

/**
* 删除任务记录
*/

export const deleteTask = (schoolId, taskId) => {
  return request({
    url: `/awardcard/api/task/${schoolId}/${taskId}`,
    method: 'delete'
  })
}

export const exportTeacher = (params) => {
  return request({
    url: '/awardcard/api/card_stat/teacher/export',
    method: 'get',
    params,
    responseType: 'blob'
  })
}

export const exportStudent = (params) => {
  return request({
    url: '/awardcard/api/card_stat/student/export',
    method: 'get',
    params,
    responseType: 'blob'
  })
}

/**
 * 获取年级信息
 */

 export const getGradeList = (schoolId) => {
  return requests({
    url: `/base/getClassList?schoolId=${schoolId}`,
    method: 'get',
  })
}

/**
 * 获取班级信息
 */

export const getClassList = (data) => {
  data.classType = 0
  return requests({
    url: `/base/api/v1/class/getClassList`,
    method: 'post',
    data
  })
}
