<!--
 * @Description: 
 * @Author: 小亓
 * @Date: 2023-05-18 15:15:34
 * @LastEditTime: 2023-07-28 18:47:01
 * @LastEditors: Please set LastEditors
-->
<template>
  <div class="content">
    <div class="main_title"> {{ title }} </div>
    <div class="main">
      <Header class="headers"></Header>
      <AppMain class="appMain"></AppMain>
    </div>
  </div>
</template>

<script>
import AppMain from "./components/AppMain";
import Header from "./components/Header";
export default {
  components: {
    AppMain,
    Header,
  },
  data() {
    return {
      title: '学生考勤'
    }
  },
  created() {
    if(this.$route.fullPath.indexOf("/teacher") > -1) {
      this.title = '教师考勤';
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  // @include mediaChangeWidthSize(1400px);
  // width: 80%;
  margin: 0 auto;
  // min-width: 1400px;
  // overflow-x: auto;
  height: $height-max;
  box-sizing: $border-box;
  background: #f5f5f5;
  padding: $main-padding 0;

  .main_title {
    font-size: 24px;
    font-weight: 500;
    color: #3c4154;
    margin-bottom: $main-padding;
  }
  .silder {
    width: 166px;
    flex-shrink: 0;
    @include mediaChangeMarginRight(24px);
  }

  .main {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;

    .headers {
      height: 58px;
      background: $main-bg;
      border-radius: 2px;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
      margin-bottom: $main-padding;
      padding: 20px 34px 4px 34px;
    }

    .appMain {
      height: calc(100% - 142px);
      overflow: auto;
    }
  }
}

@media screen and (max-width: 1400px) {
  .content {
    // width: 80%;
  }
}
</style>
