<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { getUrlKey } from '@/utils'
export default {
  name: 'App',
  data () {
    return {

    };
  },
  async created () {
    if (window.location.href.indexOf('token') > -1) {
      await this.setToken(getUrlKey('token'));
    }

  },
  methods: {
    ...mapActions(['setToken']),
  },

};
</script>

<style scoped lang="scss">
#app {
  overflow: hidden;
  background: $main-bg-color;
}
</style>
<style lang="css">
@import '//at.alicdn.com/t/font_2000189_96q5j5fevw.css?';
.iconfont {
  font-size: 14px;
}
[class*=' el-icon-'],
[class^='el-icon-'] {
  font-size: 14px;
}
</style>
