<template>
  <div class="basic-block">
    <div class="top">
      <div class="search">
        <slot name="search"></slot>
      </div>
      <div class="buttons">
        <slot name="buttons"></slot>
      </div>
    </div>
    <div class="main_content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
//奖卡统一页面设置
export default {
  name: "basicBlock",
  props: {
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.basic-block {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  // overflow: hidden;
  background: $main-bg;
  border-radius: 2px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
  padding: 24px 32px;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .search {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .title {
      border-left: 4px solid $main-color;
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      padding-left: 18px;
      border-bottom-right-radius: 4px;
    }
  }
  .main_content {
    height: calc(100% - 54px);
    position: relative;
    width: 100%;
    margin-top: 24px;
  }
}
</style>
