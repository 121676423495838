<template>
  <div class="basic-block">
    <div class="top" v-if="isShowTitle">
      <div class="title">
        <!-- {{ title }} -->
      </div>
      <div class="search">
        <slot name="search"></slot>
      </div>
    </div>
    <div :class="isShowTitle ? 'main_content main_content1' : 'main_content'">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "basicBlock",
  props: {
    //是否显示title
    isShowTitle: {
      type: Boolean,
      default() {
        return true;
      },
    },
    //title内容
    title: {
      type: String,
      default() {
        return "标题";
      },
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.basic-block {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: auto;
  // overflow: hidden;
  background: $main-bg;
  border-radius: 2px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.05);
  padding: 24px 32px;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 0;
    top: 24px;
    width: calc(100% - 32px);
    .search {
      .el-row {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
    .title {
      border-left: 4px solid $main-color;
      font-size: 18px;
      font-weight: 500;
      color: #333333;
      padding-left: 18px;
      border-bottom-right-radius: 4px;
    }
  }
  .main_content {
    height: 100%;
  }
  .main_content1 {
    margin-top: 48px;
    height: calc(100% - 48px);
    overflow-y: auto;
  }
}
</style>
