import { getTokenValue } from '@/http/api'
// import { getUrlKey } from '@/utils'
import { getGradeList } from "@/http/awardCard.js";


const hrefUrl = location.href
const url = hrefUrl.split('?')[1] || ''
  let theRequest = {}
  console.log(url)
  if (url) {
    let strs = url.split('&')
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = decodeURIComponent(
        strs[i].split('=')[1]
      )
    }
    localStorage.setItem('token',theRequest.token)
  }

const getDefaultState = () => {
  return {
    token: localStorage.getItem('token') ||
      '',
    userInfo:
      (sessionStorage.getItem('userInfo') &&
        JSON.parse(sessionStorage.getItem('userInfo'))) ||
      {}, // 老师信息
    yearsInfo: [], // 学校学年信息
    termListInfo: [], // 学校学期列表
    userType: '', // 当前选择的哪种成绩
    scoreType: { title: '原始成绩', type: 'original' }, //  首页的路由，名称
    ScoreTypeList: [], // 成绩类型列表
    ScoreLevelList: [], // 成绩类型列表
    allGrade: [], //学校所有年级
    allClass: [], //学校所有班级
    isLoad: false //用户信息是否已加载完成
  }
}

const state = getDefaultState()

const mutations = {
  SET_TOKEN: (state, token) => {
    sessionStorage.setItem('token', JSON.stringify(token))
    state.token = token
  },
  SET_LOAD: (state, bool) => {
    state.isLoad = bool
  },
  SET_USER_INFO: (state, userInfo) => {
    state.userInfo = userInfo
  },
  SET_YEARS_INFO: (state, yearsInfo) => {
    state.yearsInfo = yearsInfo
  },
  SET_ALL_GRADE: (state, data) => {
    state.allGrade = data
  },
  SET_ALL_CALSS: (state, data) => {
    state.allClass = data
  },
  SET_TERMLIST_INFO: (state, termListInfo) => {
    state.termListInfo = termListInfo
  },
  SET_SCORE_TYPE: (state, scoreType) => {
    state.scoreType = scoreType
  },
  SET_SCORETYPELIST: (state, data) => {
    state.ScoreTypeList = data
  },
  SET_SCORELEVELLIST: (state, data) => {
    state.ScoreLevelList = data
  },
}

const actions = {
  setToken({ commit }, token) {
    window.localStorage.setItem('token', token)
    commit('SET_TOKEN', token)
  },
  // 这里拿到token，进行获取用户信息
  async getUserInfo(data, args) {
    const { commit, state } = data
    // return new Promise((resolve, reject) => {
    let res = await getTokenValue({ accountToken: args || state.token })
    console.log(res.data.schoolId)
    res.data.tnId = res.data.schoolId
    if(res.retCode != 1){
      return
    }
    let res2 = await getGradeList(res.data.schoolId);
    console.log(res2.data)
    commit('SET_ALL_GRADE', res2.data)
    // let res3 = await getClassList({
    //   schoolId: res.bizData.tnId,
    // });
    
    commit('SET_ALL_CALSS', res2.data)
    sessionStorage.setItem('userInfo', JSON.stringify(res.data))
    commit('SET_USER_INFO', res.data)
    // let res1 = await getGrade({
    //   token: args || state.token,
    //   schoolId: res.data.schoolId,
    // })
    if (res.retCode == '1') {
      commit('SET_YEARS_INFO', res2.data)
    }
    commit('SET_LOAD', true)
  },
}

export default {
  // namespaced: true,
  state,
  mutations,
  actions,
}
