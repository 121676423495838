import request from './request'
import requests from './requests'

/**
 * 根据tocken查询用户数据
 */

export function getTokenValue(params) {
  return requests({
    url: '/base/queryUserInfoByAccountToken',
    method: 'get',
    params,
  })
}

/**
 * 根据tocken和学校Id查询当前学校年级
 */

export function getGrade(params) {
  return requests({
    url: '/base/grade/getGrade',
    method: 'get',
    params,
  })
}

/**
 * 根据学校Id查询当前学校学期列表
 */

export function getClassList(params) {
  return requests({
    url: '/base/class/getClassList',
    method: 'get',
    params,
  })
}

/**
 * 权限管理-查询学校角色列表
 */

export function querySchoolRoles(params) {
  return requests({
    url: '/base/resource/querySchoolRoles',
    method: 'get',
    params,
  })
}

/**
 * 权限管理-查询学校已开通的功能
 */

export function querySchoolResource(params) {
  return requests({
    url: '/base/resource/querySchoolResource',
    method: 'get',
    params,
  })
}

/**
 * 权限管理-查询可见的数据模块
 */

export function queryDataModule(data) {
  return requests({
    url: '/base/resource/queryDataModule',
    method: 'post',
    data,
  })
}

/**
 * 权限管理-查询角色可见的功能
 */

export function queryRoleResource(params) {
  return requests({
    url: '/base/resource/queryRoleResource',
    method: 'get',
    params,
  })
}

/**
 * 权限管理-查询角色数据模块的权限设置
 */

export function queryRoleDataArea(params) {
  return requests({
    url: '/base/resource/queryRoleDataArea',
    method: 'get',
    params,
  })
}

/**
 * 权限管理-保存角色
 */

export function saveRole(data) {
  return requests({
    url: '/base/resource/saveRole',
    method: 'post',
    data,
  })
}

/**
 * 权限管理-删除角色
 */

export function deleteRole(params) {
  return requests({
    url: '/base/resource/deleteRole',
    method: 'get',
    params,
  })
}

// ===============导入课表===============
/**
 * 导入课表-获取当前学段
 */

export function getSchoolTerm(data) {
  return requests({
    url: '/base/getSchoolTerm',
    method: 'get',
    data,
  })
}

/**
 * 导入课表-下载模板
 */

export function downloadCourseTableExcel(params) {
  return requests({
    url: '/base/saas/manageCourse/downloadCourseTableExcel',
    method: 'get',
    responseType: 'blob',
    params,
  })
}

/**
 * 导入课表-保存课表
 */

export function saveScheduleCourse(data) {
  return requests({
    url: '/base/saas/manageCourse/saveScheduleCourse',
    method: 'post',
    data,
  })
}

/**
 * 课时统计-查询教师上课课时统计
 */

export function queryTeacherTimes(data) {
  return requests({
    url: '/base/saas/timesSummary/queryTeacherTimes',
    method: 'post',
    data,
  })
}
/**
 * 课时统计-查询教师课时变更明细
 */

export function queryTeacherTimesChange(data) {
  return requests({
    url: '/base/saas/timesSummary/queryTeacherTimesChange',
    method: 'post',
    data,
  })
}

/**
 * 课时统计-查询年级的学科
 */

export function manager(params, gradeStr, token) {
  return requests({
    url: '/base/course/get/manager?token=' + token + gradeStr,
    method: 'get',
    params,
  })
}

/**
 * 课时统计-获取当前学校的节次
 */

export function getTeachTimeInfo(params) {
  return request({
    url: '/teachTimeInfo/getTeachTimeInfo.do',
    method: 'get',
    params,
  })
}

/**
 * 课时统计-添加老师课时变更记录
 */

export function saveTeacherTimesChange(data) {
  return requests({
    url: '/base/saas/timesSummary/saveTeacherTimesChange',
    method: 'post',
    data,
  })
}

/**
 * 课时统计-导出课时统计
 */

export function exportTeacherTimes(data) {
  return requests({
    url: '/base/saas/timesSummary/exportTeacherTimes',
    method: 'post',
    responseType: 'blob',
    data,
  })
}

/**
 * 课时统计-查询教师周课时
 */

export function queryTeacherWeekTimes(data) {
  return requests({
    url: '/base/saas/timesSummary/queryTeacherWeekTimes',
    method: 'post',
    data,
  })
}

/**
 * 课时统计-查询时段设置，内含年级数据
 */

export function querySimpleTeachTime(params) {
  return requests({
    url: '/base/teachTimeInfo/querySimpleTeachTime',
    method: 'get',
    params,
  })
}

/**
 * 课时统计-获取当前学期时间
 */

export function currentTerm(params) {
  return requests({
    url: '/base/grade/currentTerm',
    method: 'get',
    params,
  })
}
