const getters = {
  token: (state) => state.user.token,
  isLoad: (state) => state.user.isLoad,
  userInfo: (state) => state.user.userInfo,
  yearsInfo: (state) => state.user.yearsInfo,
  allNGrade: (state) => state.user.allGrade,
  allNClass: (state) => state.user.allClass,
  scoreType: (state) => state.user.scoreType,
  termListInfo: (state) => state.user.termListInfo,
  ScoreTypeList: (state) => state.user.ScoreTypeList,
  ScoreLevelList: (state) => state.user.ScoreLevelList,
}

export default getters
